<template>
  <div class="OffreCessionPublicProfile" >
    <div id="annonce-images">
      <div class="container">
        <b-modal size="lg" ref="createCandidatureModal" hide-footer>
          <template v-slot:modal-title>
            <strong> Candidater à cette offre </strong>
          </template>
          <div v-if="!isAuthenticated">
            <p class="mt-3">
              <strong class="gavc-text-rose">
                Veuillez créer votre compte
                ou vous connecter pour pouvoir
                candidater à une offre de cession
              </strong>
            </p>
            <div class="row">
              <div class="col-12 text-center">
                <button
                  class="gavc-btn mt-3"
                  @click="goToRepreneurRegistration"
                >
                  Je crée mon compte repreneur
                </button>
              </div>
            </div>
            <small>
              <b-button
                variant="link"
                @click="$router.push('/se-connecter')">
                → J'ai déjà un compte
              </b-button>
            </small>
          </div>
          <div v-else-if="userGroupContains(['REPRENEUR'])">
            <createCandidature
              :offreCessionId="annonce.offre_cession.id"
            />
          </div>
          <div v-else>
            <strong class="text-danger">
              Veuillez créer un compte repreneur pour pouvoir
              candidater à une offre de cession
            </strong>
            <b-button
              class="mt-4"
              @click="goToRepreneurRegistration"
              block
              pill
              variant="outline-primary">
              Je crée mon compte repreneur
            </b-button>
          </div>
        </b-modal>

        <div class="gavc-images">
          <div class="gavc-carousel-wrapper">
            <div class="gavc-carousel">
              <figure class="gavc-carousel__item">
                <img
                  v-if="annonce.images.length"
                  :src="imageUrl(annonce.images[0])"
                  @click="showCarousselImage(0)"
                  alt="image de l'annonce"
                >
                <img
                  v-else
                  :src="require('@/assets/images/default.jpg')"
                >

                <div 
                  v-if="annonce.images.length"
                  class="gavc-carousel__count" 
                  @click="showCarousselImage(0)"
                >
                    {{showCarousselButtonText}} 
                </div>
              </figure>
              <figure class="gavc-carousel__item">
                <img 
                  @click="showCarousselImage(1)"
                  v-if="annonce.images.length > 1" 
                  :src="imageUrl(annonce.images[1])"
                >
              </figure>
              <figure class="gavc-carousel__item">
                <img 
                  @click="showCarousselImage(2)"
                  v-if="annonce.images.length > 2" 
                  :src="imageUrl(annonce.images[2])"
                >
              </figure>
            </div>
            <div :class="{'gavc-carousel-overlay': true, 'is-active': showCaroussel}">
              <button 
                @click="closeCaroussel"
                class="gavc-carousel-overlay__close-btn">
                <i class="gavc-icon gavc-icon-cross"></i>
              </button>
              <div class="gavc-carousel-overlay__wrapper">
                <div class="gavc-carousel-overlay__img">
                  <figure>
                    <img :src="imageUrl(carousselImage)">
                  </figure>
                  <div class="gavc-carousel-overlay__nav">
                    <button 
                      v-if="annonce.images.length > 1"
                      @click="previousCarousselImage"
                      class="gavc-btn-circle gavc-btn-circle--chevron gavc-carousel__prev-btn"
                    >
                      <i class="gavc-icon gavc-icon-chevron-left"></i>
                    </button>
                    <button 
                      v-if="annonce.images.length > 1"
                      @click="nextCarousselImage"
                      class="gavc-btn-circle gavc-btn-circle--chevron gavc-carousel__next-btn"
                    >
                      <i class="gavc-icon gavc-icon-chevron-right"></i>
                    </button>
                  </div>
                </div>
                <div 
                  v-if="annonce.images.length > 1"
                  class="gavc-carousel-overlay__preview"
                >
                  <figure 
                    v-for="(image, index) in annonce.images"
                    :key="`caroussel-preview-image-${index})`"
                  >
                    <img @click="carousselImageIndex = index" :src="imageUrl(image)" style="cursor: pointer;"> 
                  </figure> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <svg width="100%" height="100%" viewBox="0 0 1300 65"
      version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      xmlns:serif="http://www.serif.com/"
      style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;background-color: #EBE9E0; margin-top: -400px;">
      <g transform="matrix(1,0,0,1,0,0.000254128)">
        <path d="M0,64.98L0,5.7C52.633,-1.581 127.9,-2.645 234.829,6.978C692,48.124 595.362,56.7 888.991,61.838C1055.25,64.75 1203.07,36.327 1300,11.125L1300.01,65.012L0,64.98Z"
              fill="#F7F6F0"
              style="fill-rule:nonzero;">
        </path>
      </g>
    </svg>

    <div class="container">

      <div class="annonce-header">
        <h1 class="gavc-h1">
          Offre de cession 
          <span class="gavc-text-green">
            {{annonce.offre_cession.entreprise_cedee.name}}
          </span>
        </h1>
        <p class="gavc-chapo"> 
          {{annonce.title}} 
          <br>
          Prix : 
          <span
            v-if="annonce.price && annonce.price > 0"
          >
            {{formatThousandSeparatorNumber(Number(annonce.price).toFixed(0))}} €
          </span>
          <span
            v-else-if="annonce.price_category"
          >
            {{priceCategoryRender(annonce.price_category)}}
          </span>
        </p>
        <p class="gavc-text-small">
          <i class="gavc-icon gavc-icon-pin"></i> {{annonce.offre_cession.entreprise_cedee.city}} (siège social)
        </p>
      </div>

      <div class="annonce-content">
        <p class="gavc-title-price" id="annonce-information-title"> Informations sur l'entreprise</p>
        <div class="row mt-4">
          <div class="col-12 col-md-6">
            <p class="gavc-chapo gavc-text-green">
              Raison sociale
            </p> 
            <p class="gavc-p">
              {{annonce.offre_cession.entreprise_cedee.name}}
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p class="gavc-chapo gavc-text-green">
              Forme sociale
            </p> 
            <p class="gavc-p">
              {{annonce.offre_cession.entreprise_cedee.forme_sociale}}
            </p>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-md-6">
            <p class="gavc-chapo gavc-text-green">
              Localisation
            </p> 
            <p class="gavc-p">
              {{annonce.offre_cession.entreprise_cedee.zip_code}}, {{annonce.offre_cession.entreprise_cedee.city}}
            </p>
          </div>
          <div 
            v-if="annonce.website && annonce.website !== 'null'"
            class="col-12 col-md-6"
          >
            <p class="gavc-chapo gavc-text-green">
              Site internet
            </p> 
            <p class="gavc-p">
              <a :href="annonceUrl" target="_blank" rel="noopener noreferrer"> {{annonce.website}}</a>
            </p>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <p class="gavc-chapo gavc-text-green">
              Description de l'activité
            </p>
            <p class="gavc-p">
              {{annonce.activite}}
            </p>
          </div>
        </div>


        <div class="row mt-4">
          <div class="col-12 col-md-6">
            <p class="gavc-chapo gavc-text-green">
              Chiffre d'affaires
            </p> 
            <p class="gavc-p">
              {{caCategoryRender(annonce.ca_category)}}
            </p>
          </div>
          <div class="col-12 col-md-6">
            <p class="gavc-chapo gavc-text-green">
              Nombre de salariés
            </p> 
            <p class="gavc-p">
              {{annonce.nb_sal}}
            </p>
          </div>
        </div>

        <div class="row mt-4">
          <div class="col-12">
            <p class="gavc-chapo gavc-text-green">
              L'entreprise est
            </p>
            <p>
              {{annonceTypeLocauxExploitation(annonce.type_locaux_exploitation)}}
            </p>
          </div>
        </div>

        <div 
          v-if="annonce.information"
          class="row mt-4">
          <div class="col-12">
            <p class="gavc-chapo gavc-text-green">
              Informations complémentaires
            </p>
            <p>
              {{annonce.information}}
            </p>
          </div>
        </div>
      </div>

      <a href="#" class="gavc-btn-circle gavc-btn-circle--chevron gavc-to-top-button">
        <i class="gavc-icon gavc-icon-chevron-up" ></i>
      </a>

      <div
        v-if="!userGroupContains(['CEDANT'])"
        class="row mt-4 text-center"
      >
        <div class="col-12 col-md-6">
          <button
            class="gavc-btn gavc-btn--bone"
            @click="shareAnnonce"
          >
            Partager
          </button>
        </div>
        <div class="col-12 col-md-6">
          <button
            class="gavc-btn"
            @click="apply"
          >
            Je suis intéressé
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { apiBaseUrl} from '@/variables/localVariables'

import { APIDisconnectedService } from '@/api/APIDisconnected'
import copyMixins from '@/mixins/copyMixins'
import renderMixins from '@/mixins/renderMixins'
import userMixins from '@/mixins/userMixins'
import { vueBaseUrl } from '@/variables/localVariables'


const apiDisconnectedService = new APIDisconnectedService()
const createCandidature = () => import('@/components/candidature/createCandidature')

const { mapFields } = createHelpers({
  mutationType: 'updateFieldWithLocalStorage',
});


export default {
  name: 'OffreCessionPublicProfile',
  props: {
    withBaseApiUrl: {
      default: true,
      type: Boolean
    }
  },
  components: {
    createCandidature
  },
  mixins: [
    copyMixins,
    renderMixins,
    userMixins
  ],
  data: function () {
    return {
      annonce: {
        activite: '',
        ca: '',
        element_sold: "",
        image: "",
        information: "",
        nb_sal: "",
        offre_cession: {
          entreprise_cedee: {
            cedant: {

            }
          }
        },
        price: "",
        title: "",
        type_locaux_exploitation: ""
      },
      carousselImageIndex: 0,
      showCaroussel: false,
    }
  },
  computed: {
    ...mapFields([
      "repreneurRegistrationStep",
      'repreneur.hasIdentifyCompany',
    ]),
    isAuthenticated () {
      return this.$store.getters.isAuthenticated
    },
    token () {
      return this.$store.state.auth.token
    },
    annonceUrl () {
      if (this.annonce.website) {
        if (this.annonce.website.includes('http://') ||  this.annonce.website.includes('https://')) {
          return this.annonce.website
        }
        else {
          return `//${this.annonce.website}`
        }
      }
      else {
        return this.annonce.website
      }
    },
    carousselImage () {
      if (this.carousselImageIndex <= this.annonce.images.length -1) {
        return this.annonce.images[this.carousselImageIndex]
      }
      return {file: null}
    },

    showCarousselButtonText () {
      if (this.annonce.images.length == 1) {
        return `Voir la photo`
      }
      else {
        return `Voir les ${this.annonce.images.length} photos`
      }
    }
  },
  created: function() {
    this.getAnnonce()
  },
  methods: {
    apply () {
      this.$refs.createCandidatureModal.show()
    },
    closeCaroussel () {
      this.showCaroussel = false
    },
    getAnnonce () {
      this.$store.commit('loadFromAPI')
      apiDisconnectedService.getAnnoncePublicProfile(this.$route.params.annonceId)
      .then((result) => {
        this.annonce = result.data
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('AnnoncePublicProfile getAnnonce API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },
    goToRepreneurRegistration () {
      this.repreneurRegistrationStep = 1
      this.hasIdentifyCompany = false
      this.$router.push('/inscription/repreneur')
    },
    imageUrl (image) {
      if (this.withBaseApiUrl) {
        return apiBaseUrl + image.file
      }
      return image.file
    },
    nextCarousselImage () {
      if (this.carousselImageIndex >= this.annonce.images.length - 1) {
        this.carousselImageIndex = 0
      }
      else {
        this.carousselImageIndex ++
      }
    },
    previousCarousselImage () {
      if (this.carousselImageIndex <= 0) {
        this.carousselImageIndex = this.annonce.images.length - 1 
      }
      else {
        this.carousselImageIndex --
      }
    },
    shareAnnonce () {
      this.copyCodeToClipBoard(`${vueBaseUrl}/offres-de-cession/public/${this.annonce.id}`)
      this.$bvToast.hide('notification-toast')
      this.$bvToast.toast("Le lien de l'annonce a bien été copié dans votre presse-papiers", {
        id: 'notification-toast',
        title: 'Lien copié',
        variant: 'success',
        solid: true
      })
    },
    showCarousselImage (index) {
      this.carousselImageIndex = index
      this.showCaroussel = true
    }
  }
}
</script>

<style lang="scss">
@import '@/styles/_variables.scss';

.annonce-header {
  margin-top: 20px;
}

.annonce-content {
  margin-top: 40px;
}
.gavc-carousel-wrapper {
  flex: 3
}

.gavc-to-top-button {
  position: fixed;
  bottom: 30px;
  right: 50px;
  cursor: pointer;
  padding: 15px;
  z-index: 999;
}

#annonce-images {
  background: linear-gradient(0deg, $white-lighten-1 50%, $white-darken-1 50%); 
  margin-top: -20px;
  padding-top: 20px;
}

#annonce-information-title {
  font-weight: 400;
  font-size: 1.8rem;
}


@media (min-width: 1024px) {
  .annonce-header-row {
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -ms-flex-align: center;
    padding: 20px;
  }

  .annonce-content {
    padding-left: 50px;
    padding-right: 50px;
  }
}
</style>
